<template>
  <a-modal
    class="video-modal"
    :width="width"
    :visible="visible"
    :footer="null"
    :zIndex="9999"
    :maskClosable="false"
    :closable='false'
    @cancel="handleCancel"
  >
    <div class="video-content" :style="backCss">
      <img
        id="video-image"
        :style="{ userSelect: 'none', transform: `rotate(${angle}deg)`, maxWidth: width, height: 'auto' }"
        v-if="imgShow"
        @click.stop
        :src="url"
        alt=""
      />
      <video
        v-if="videoShow"
        @click.stop
        preload="auto"
        width="800px"
        height="450px"
        :src="url"
        :autoplay="true"
        controls
      ></video>
      <div v-if="imgShow && actionShow" class="image-action" @click.stop>
        <div><a-icon type="zoom-in" @click="scale('amp')" /></div>
        <div><a-icon type="zoom-out" @click="scale('reduce')" /></div>
<!--         <div><a-icon style="transform: rotate(90deg);" @click="rorate('left')" type="undo" /></div>
        <div><a-icon style="transform: rotate(270deg);" @click="rorate('right')" type="redo" /></div> -->
      </div>
      <div v-if="actionShow" class="image-close" @click="handleCancel">
        <a-icon type="close" />
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '1000px'
    },
    isSys:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      visible: false,
      url: '',
      imgShow: false,
      videoShow: false,
      actionShow: false,
      color: '',
      angle: 0
    }
  },
  computed: {
    backCss() {
      this.color = this.$store.state.app.color
      return {
        '--theme-color': this.color
      }
    }
  },
  methods: {
    scale(type) {
      if (type == 'amp') {
        document.getElementById('video-image').style.width =
          document.getElementById('video-image').clientWidth + 10 + 'px'
        if (document.getElementById('video-image').clientWidth + 10 > 1000) {
          this.$emit('changeVideoWidth', document.getElementById('video-image').clientWidth + 10 + 'px')
        }
      } else {
        if (document.getElementById('video-image').clientWidth > 50) {
          document.getElementById('video-image').style.width =
            document.getElementById('video-image').clientWidth - 10 + 'px'
        }
      }
    },
    open(url) {
      this.url = url
      if (url.indexOf('jpg') > -1 || url.indexOf('png') > -1||this.isSys) {
        this.imgShow = true
        this.visible = true
        this.videoShow = false
      }
      if ((url.indexOf('mp4') > -1 || url.indexOf('mpeg') > -1)&&!this.isSys) {
        this.imgShow = false
        this.visible = true
        this.videoShow = true
      }
      if (!this.visible) {
        this.imgShow = true
        this.visible = true
        this.videoShow = false
      }
      const timeOut = setTimeout(() => {
        this.actionShow = true
        clearTimeout(timeOut)
      }, 500)
    },
    rorate(type) {
      if (type == 'left') {
        this.angle = this.angle - 90
      } else {
        this.angle = this.angle + 90
      }
    },
    handleCancel() {
      this.url = ''
      this.imgShow = false
      this.actionShow = false
      this.videoShow = false
      this.visible = false
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped lang="less">
.video-content {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  min-height: 600px;
  /* position: relative; */
  .image-action {
    position: fixed;
    bottom: 20px;
    height: 40px;
    width: 180px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    &:hover {
      background: rgba(0, 0, 0, 0.5);
    }
    display: flex;
    padding-left: 40px;
    padding-right: 40px;
    line-height: 40px;
    font-size: 16px;
    justify-content: space-between;
    color: #fff;
    > div {
      &:hover {
        > .anticon {
          transition: 0.3s all;
          cursor: pointer;
          color: var(--theme-color);
        }
      }
    }
  }
  .image-close {
    position: fixed;
    top: 20px;
    right: 20px;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    height:30px;
    width:30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:16px;
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
