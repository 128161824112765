var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      staticClass: "video-modal",
      attrs: {
        width: _vm.width,
        visible: _vm.visible,
        footer: null,
        zIndex: 9999,
        maskClosable: false,
        closable: false,
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c("div", { staticClass: "video-content", style: _vm.backCss }, [
        _vm.imgShow
          ? _c("img", {
              style: {
                userSelect: "none",
                transform: `rotate(${_vm.angle}deg)`,
                maxWidth: _vm.width,
                height: "auto",
              },
              attrs: { id: "video-image", src: _vm.url, alt: "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            })
          : _vm._e(),
        _vm.videoShow
          ? _c("video", {
              attrs: {
                preload: "auto",
                width: "800px",
                height: "450px",
                src: _vm.url,
                autoplay: true,
                controls: "",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            })
          : _vm._e(),
        _vm.imgShow && _vm.actionShow
          ? _c(
              "div",
              {
                staticClass: "image-action",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c(
                  "div",
                  [
                    _c("a-icon", {
                      attrs: { type: "zoom-in" },
                      on: {
                        click: function ($event) {
                          return _vm.scale("amp")
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("a-icon", {
                      attrs: { type: "zoom-out" },
                      on: {
                        click: function ($event) {
                          return _vm.scale("reduce")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.actionShow
          ? _c(
              "div",
              { staticClass: "image-close", on: { click: _vm.handleCancel } },
              [_c("a-icon", { attrs: { type: "close" } })],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }